import axios from "@commons/infra/http/axios/axios";
import {defaultScheduleSizeInDays} from "./DoctorService";

export const DoctorApi = {

    getDoctors: ({search, speciality}) => {
        let url = new URL(`/api/doctors`, axios.defaults.baseURL);

        if (search) {
            url.searchParams.append('name', search);
        }
        speciality.forEach(s => {
            url.searchParams.append('specialities', s);
        });

        return axios.get(url.href)
    },

    getDoctorSpecialities: () => axios.get('/api/doctors/specialities'),

    getAuthorities: () => axios.get('/api/doctor/authority'),

    applyAuthority: (doctorId) => {
        return axios.post('/api/doctor/authority/apply', {
            doctorId: doctorId
        })
    },

    /**
     * @param {string} doctorId
     * @param {DateTime} scheduleFrom
     * @param {int} scheduleSizeInDays
     * @returns {Promise}
     */
    getDoctor: ({doctorId, scheduleFrom, scheduleSizeInDays = defaultScheduleSizeInDays}) => {
        let scheduleTo = scheduleFrom.plus({days: scheduleSizeInDays});

        return axios.get(`/api/doctors/${doctorId}?scheduleFrom=${scheduleFrom.toUTC()}&scheduleTo=${scheduleTo.toUTC()}`)
    },

    getDoctorSlug: (doctorId) => {
        return axios.get(`/api/doctors/${doctorId}/slug`)
    },

    updateSettings: ({
                         appointment,
                         offlineAppointments,
                         appointmentRequest,
                         patientInvitation
                     }) => {
        return axios.put(`/api/doctor/settings`, {
            appointment,
            offlineAppointments,
            appointmentRequest,
            patientInvitation
        })
    },

    updateProfile: ({description}) => {
        return axios.put(`/api/doctor/profile`, {description})
    },

    registerDoctor: ({
                         authenticationId,
                         email,
                         phone,
                         password,
                         password2,
                         isMarketingAllowed,
                         areTermsAccepted
                     }) =>
        axios.post('/api/doctor/registration', {
            authenticationId: authenticationId,
            email: email,
            phone,
            password: password,
            password2: password2,
            isMarketingAllowed: isMarketingAllowed,
            areTermsAccepted: areTermsAccepted
        })
};
