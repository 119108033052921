import {
    FETCH_DOCTOR_DAILY_OVERVIEW_ASYNC,
    FETCH_DOCTOR_MONTHLY_OVERVIEW_ASYNC,
    FETCHED_DOCTOR_DAILY_OVERVIEW,
    FETCHED_DOCTOR_MONTHLY_OVERVIEW,
    FETCHED_DOCTOR_PROFILE,
    LOGIN,
    LOGOUT,
    SAVE_APPOINTMENT_REQUEST,
    TRY_LOGIN,
    TRY_LOGIN_FAILED
} from "./actions.js";
import patientReducer from "./patientSlice.js";
import stickyBannerReducer from "@commons/uinew/component/marketing/stickybanner/redux";

let initialAuthenticationState = {
    isAuthenticated: false,
    isImpersonating: false,
    email: null,
    firstName: null,
    isError: false,
    authorities: []
};

let initialState = {
    // cookieConsent: cookieConsentReducer(undefined, {}),
    counter: 0,
    authentication: {
        isUpdating: true,
        ...initialAuthenticationState
    },
    profile: {}, // doctor profile TODO doctor.profile
    patient: patientReducer(undefined, {}),
    stickyBanner: stickyBannerReducer(undefined, {}),
    doctorMonthlyOverview: {
        isLoading: null,
    },
    doctorDailyOverview: {
        isLoading: null,
    },
    appointmentRequest: {
        form: null
    }
};

export default function reducers(state = initialState, action) {
    switch (action.type) {

        case TRY_LOGIN:
            return {
                ...state,
                authentication: {
                    isUpdating: true
                }
            };

        case TRY_LOGIN_FAILED:
            return {
                ...state,
                authentication: {
                    ...initialAuthenticationState,
                    isError: true
                }
            };

        case LOGIN:
            return {
                ...state,
                authentication: {
                    isUpdating: false,
                    isAuthenticated: true,
                    isImpersonating: action.payload.isImpersonating,
                    email: action.payload.email,
                    publicSessionId: action.payload.publicSessionId,
                    authorities: action.payload.authorities,
                    userId: action.payload.userId
                }
            };

        case FETCHED_DOCTOR_PROFILE:
            return {
                ...state,
                profile: action.payload
            };

        case FETCH_DOCTOR_MONTHLY_OVERVIEW_ASYNC:
            return {
                ...state,
                doctorMonthlyOverview: {
                    isLoading: true
                }
            };

        case FETCHED_DOCTOR_MONTHLY_OVERVIEW:
            return {
                ...state,
                doctorMonthlyOverview: {
                    isLoading: false,
                    ...action.payload.stats
                }
            }

        case FETCH_DOCTOR_DAILY_OVERVIEW_ASYNC:
            return {
                ...state,
                doctorDailyOverview: {
                    isLoading: true
                }
            }

        case FETCHED_DOCTOR_DAILY_OVERVIEW:
            return {
                ...state,
                doctorDailyOverview: {
                    isLoading: false,
                    ...action.payload.stats
                }
            }

        case LOGOUT:
            return {
                ...state,
                authentication: {
                    ...initialAuthenticationState,
                    useReturnPath: action.payload.useReturnPath,
                    isUpdating: false
                },
                profile: {}
            };

        case SAVE_APPOINTMENT_REQUEST:
            return {
                ...state,
                appointmentRequest: action.payload
            }

        default:
            return {
                ...state,
                patient: patientReducer(state.patient, action),
                stickyBanner: stickyBannerReducer(state.stickyBanner, action),
                // cookieConsent: cookieConsentReducer(state.cookieConsent, action),
            }
    }
}
